import StartPage from './templates/startPage/StartPage';
import DataArticlePage from './templates/dataArticlePage/DataArticlePage';
import BoxedContentBlock from './components/contentBlocks/BoxedContent/BoxedContentBlock';
import FileExplorerBlock from './components/contentBlocks/fileExplorerBlock/FileExplorerBlock';

export const EPI_COMPONENTS = [
    {
        id: 'DataStartPage',
        component: StartPage
    },
    {
        id: 'DataArticlePage',
        component: DataArticlePage
    },
    {
        id: 'BoxedContentBlock',
        component: BoxedContentBlock
    },
    {
        id: 'FileExplorerBlock',
        component: FileExplorerBlock
    }
];

export default EPI_COMPONENTS;
